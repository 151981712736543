import React from "react"

import { graphql, useStaticQuery, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

import "./hero.css"

const QuandaryBanner = () => {
  // const { heroImage } = useStaticQuery(
  //   graphql`
  //     {
  //       heroImage: file(
  //         relativePath: { eq: "new-images/quandary-build-bg.jpeg" }
  //       ) {
  //         childImageSharp {
  //           gatsbyImageData(
  //             width: 1600
  //             layout: CONSTRAINED
  //             formats: [AUTO, WEBP, AVIF]
  //           )
  //         }
  //       }
  //     }
  //   `
  // )

  // const heroImageData = getImage(heroImage)

  // Use like this:
  // const bgImage = convertToBgImage(heroImageData)

  return (
    <>
      <div
        // Tag="div"
        // {...bgImage}
        // // fluid={imageData}
        className="hero-banner hero-whitefade-quandary-banner"
        style={{
          backgroundPosition: "top center",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-7 mr-auto">
              <div className="hero-procurement">
                <p className="h5 text-light-blue text-uppercase font-weight-bold mt-5 mt-md-0 mb-4 text-left-mobile">
                  QUANDARY BUILD
                </p>
                <h1 className="page-title font-weight-medium mt-0 mb-4">
                  Don't Settle for Expensive Application Development
                </h1>
                <p>
                  We build enterprise-level applications designed to scale your
                  business without the enterprise-level price tag.
                </p>
                <p>
                  <Link
                    aria-label="link"
                    className="font-weight-medium"
                    to="/contact/"
                  >
                    Speak to a Technology Consultant
                    <i className="ml-2 item-arrow" aria-hidden="true"></i>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default QuandaryBanner
