import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { FaHandshake, FaPeopleCarry } from "react-icons/fa"
import { GiFreedomDove } from "react-icons/gi"
import { HiLightBulb } from "react-icons/hi"
import { LazyLoadComponent } from "react-lazy-load-image-component"

import Layout from "../components/layout"
import Seo from "../components/seo"
// import PartnerSwiper from "../components/swiper/swiper-partner.js"
// import Testimonials from "../components/swiper/testimonials-new"
// import Cases from "../components/cases"
import Banner from "../components/hero/quandary-banner.js"

import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"

const PartnerSwiper = loadable(() =>
  pMinDelay(import("../components/swiper/swiper-partner"), 500)
)
const Testimonials = loadable(() =>
  pMinDelay(import("../components/swiper/testimonials-new"), 500)
)

const Cases = loadable(() => import("../components/cases"))

class QuandaryBuildPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location} banner={<Banner />}>
        <Seo
          title="Custom Low-Code Application Development Services | Quandary"
          description="Our Denver-based team provides custom software development and ongoing consulting services for mid-market and enterprise clients using low-code."
        />

        <div className="page-content pt-0 pb-0">
          <PartnerSwiper />

          <section style={{ padding: "3rem 0", backgroundColor: "#e7e7e7" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="intro mb-4">
                    <h2 className="mt-0 mb-3">
                      Build the Business Your Customers Need
                    </h2>
                  </div>
                  <p className="mt-4">
                    Whether you have legacy systems holding you back or turnkey
                    applications that fall short, you need custom applications
                    designed specifically for your needs. After all, you face
                    unique challenges that require unique solutions.
                  </p>
                  <p>
                    Quandary Build leverages the cost-effective design of low
                    code platforms and the experience of expert developers to
                    rapidly build the custom applications you need. And we do it
                    faster and cheaper than traditional application or in-house
                    development...
                  </p>
                  <p>
                    Design unique solutions. Connect systems. Scale faster.
                    Reduce waste. Gain actionable insights at a glance. We build
                    the infrastructure your organization needs to remain
                    competitive.
                  </p>

                  <div className="text-left my-2">
                    <Link
                      className="btn btn-primary btn-arrow mt-3"
                      to="/contact/"
                    >
                      Let's Chat
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 pt-5">
                  <LazyLoadComponent>
                    <iframe
                      width="100%"
                      height="380px"
                      src="https://www.youtube.com/embed/uQeNV_6luRQ"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="BySpreadSheets"
                      className="rounded"
                    ></iframe>
                  </LazyLoadComponent>
                </div>
              </div>
            </div>
          </section>
          <section
            className="quandary-build-glance"
            style={{ paddingTop: "3rem", paddingBottom: "3rem" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <h2>
                    Transform Your Business with Custom Software Development
                  </h2>
                </div>
                <div className="col-lg-6 col-md-6 mt-5 text-center">
                  <FaPeopleCarry />
                  <p className="h5 my-4" style={{ fontWeight: "bold" }}>
                    Empower Your Staff
                  </p>
                  <p>
                    Turn your employees’ unique ideas into real solutions
                    without draining your technology department or your budget.
                  </p>
                </div>
                <div className="col-lg-6 col-md-6 mt-5 text-center">
                  <HiLightBulb />
                  <p className="h5 my-4" style={{ fontWeight: "bold" }}>
                    Think Productive. Not Busy.
                  </p>
                  <p>
                    Reduce boring, menial tasks with custom applications that
                    bridge the gaps instead of spreadsheets.
                  </p>
                </div>
                <div className="col-lg-6 col-md-6 mt-5 text-center">
                  <FaHandshake />
                  <p className="h5 my-4" style={{ fontWeight: "bold" }}>
                    Exceed Customer Expectations
                  </p>
                  <p>
                    Stop fitting your business to your software solutions. Flip
                    the script and give your customers exactly what they want.
                  </p>
                </div>
                <div className="col-lg-6 col-md-6 mt-5 text-center">
                  <GiFreedomDove />
                  <p className="h5 my-4" style={{ fontWeight: "bold" }}>
                    Streamline Everything
                  </p>
                  <p>
                    Speed up workflows by eliminating slowdowns and smashing
                    through bottlenecks.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section id="case-studies-container">
            <div id="case-studies" className="bg-indigo">
              <div className="container">
                <div className="intro text-center">
                  <div className="row">
                    <div className="col-md-10 mx-auto">
                      <p className="h5 text-light-blue text-uppercase font-weight-bold m-0">
                        Case Studies
                      </p>
                      <h2 className=" my-2 text-white">
                        We Save Clients 1000s of Hours. Every Year.
                      </h2>
                      {/* <p className="text-white">
                        We helped a national healthcare provider save over 9,000
                        hours in weekly labor costs by developing a single
                        application...
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>

              <Cases allSanityCaseStudy={this.props.data.allSanityCaseStudy} />
            </div>
          </section>
          <LazyLoadComponent>
            <section
              id="it-consulting"
              className="it-consulting"
              style={{ padding: "3rem 0" }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-8 mx-auto text-center">
                    <h2 className="mb-2 mb-2 font-weight-medium">
                      Custom Application Development Delivered on
                      <br />
                      Time and Within Budget
                    </h2>
                  </div>
                </div>
                <div
                  className="row align-items-center flex-column-reverse flex-lg-row flex-md-row"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px",
                    marginTop: "3rem",
                  }}
                >
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-4 px-md-5 text-left-mobile">
                    <h3>Tools for Any Task</h3>
                    <p className="mt-4">
                      Whether you need an enterprise-grade application designed
                      to manage your entire procurement process or simple
                      automations designed to save time, Quandary Build creates
                      it for you.
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 py-lg-3 py-3">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex"
                      src="../images/new-images/tools-for-any-task.jpg"
                      alt="Tools for any task"
                      layout="constrained"
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="row align-items-center mt-3">
                  <div className="col-lg-6 col-md-6 py-lg-4 py-3">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex"
                      src="../images/new-images/scallable-applications.png"
                      alt="Scalable Applications"
                      layout="constrained"
                      loading="lazy"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-0 text-center mb-lg-0 mb-md-0 mb-3 px-md-5 text-left-mobile">
                    <h3>Scalable Applications</h3>
                    <p className="mt-4">
                      Quandary Build gives you custom applications that grow
                      with your business. Bridge gaps. Automate processes.
                      Reduce mistakes. Eliminate waste. Increase visibility.
                      Break bottlenecks. The possibilities are endless.
                    </p>
                  </div>
                </div>
                <div
                  className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-4 px-md-5 text-left-mobile">
                    <h3>Low-Code for Low-Fuss</h3>
                    <p className="mt-4">
                      We use Quickbase and other low-code platforms to rapidly
                      design and deploy lean applications. Give your business
                      more agility without bloated code, cost, or technical
                      debt.
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 py-lg-3 py-3">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex"
                      src="../images/new-images/relaxation.jpg"
                      alt="Low-Code for Low-Fuss"
                      layout="constrained"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </section>
          </LazyLoadComponent>

          <Testimonials />

          <LazyLoadComponent>
            <section
              style={{
                padding: "3rem",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12  text-center">
                    <h2 className="mb-3 text-left-mobile">
                      What Do You Need to Grow Your Business?
                    </h2>
                    <p className="text-dark text-left-mobile">
                      Get the custom applications you need to scale your
                      business. Delivered on time and within budget.
                    </p>
                    <Link
                      className="speak-to-btnx btn btn-primary btn-arrow mt-4"
                      to="/contact/"
                    >
                      Speak to a Technology Consultant
                    </Link>
                  </div>
                </div>
              </div>
            </section>
          </LazyLoadComponent>
        </div>
      </Layout>
    )
  }
}

export default QuandaryBuildPage

export const quandaryBuildPageQuery = graphql`
  query quandaryBuildPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
